import React from 'react';
// WIX_UI_TPA
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';

// API
import { memberWrapper } from '@wix/social-groups-api/dist/src/model/Member/Member';

import { FeedTopics } from '../../Discussion/FeedTopics/FeedTopics';

// STYLES
import { classes, st } from './NewPostModal.st.css';
import { RawDraftContentState } from '../../../../../common/ContentEditor/types';
import {
  InjectedExperimentsProps,
  InjectedSentryProps,
  withExperiments,
  withSentry,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../../contexts/Group/WithGroup';
import {
  IEditorEvents,
  IEditorEventsContext,
} from '../../../contexts/PostContentEditor/IEditorEvents';
import { Modal, ModalSkin } from '../../../../../common/components/Modal';
import { Loader } from '../../../../../common/components/Loader/Loader';
import { Button } from '../../../../../common/components/Button/Button';
import { Spinner } from '../../../../../common/components/Spinner';
import { RichContentEditor } from '../../RichContent/lazy';
import { compose } from '../../../../../common/utils/compose';
import { withTPAConfig } from '../../../../../common/components/withTPAConfig';
import { EFilterKeys } from '../../../types/EFilterKeys';
import { NEW_POST_MODAL } from '../../dataHooks';
import { withTheme, Theme } from '../../../../../common/context/theme';
import { TextButton } from '../../../../../common/components/TextButton/TextButton';

interface NewPostModalProps {
  isOpen: boolean;
  isPostPublishing: boolean;
  topicId?: string;
  feedItemId?: string;
  initialContentState?: RawDraftContentState<any>;

  onSubmit(post: RawDraftContentState<any>, topicId?: string): void;

  onVisibilityChange(isOpen: boolean): void;

  onSaveDraft?(post: RawDraftContentState<any>): void;
}

type Props = NewPostModalProps &
  WithTranslation &
  TPAComponentsConfig &
  WithGroupProps &
  Theme &
  InjectedSentryProps &
  InjectedExperimentsProps;

interface State {
  post: RawDraftContentState<any>;
  isBusy: boolean;
  topicId: string;
}

class NewPostModalComponent extends React.Component<Props, State> {
  static displayName = 'NewPostModalComponent';
  static defaultProps = {
    initialContentState: null,
    onSaveDraft() {},
    currentMember: {
      name: {},
    },
  };

  readonly state: State = {
    post: null as any,
    isBusy: false,
    topicId: this.props.topicId!,
  };

  componentDidUpdate(props: Props) {
    if (this.props.topicId !== props.topicId) {
      this.setState({
        topicId: this.props.topicId!,
      });
    }
  }

  private editorEvents!: IEditorEventsContext;
  private editorEventTypes!: IEditorEvents;

  protected $editor = React.createRef<any>();

  handleClose = () => {
    this.props.onSaveDraft!(this.state.post);
    this.props.onVisibilityChange(false);
  };

  setPost = (post?: any) => {
    this.setState({ post });
  };

  handleSubmit = async () => {
    try {
      await this.editorEvents.dispatch(this.editorEventTypes.PUBLISH);
    } catch (e) {
      console.log('PostEditorComponent Error in EditorEvents');
      this.props.sentry.captureException(e);
    } finally {
      const forPublish = true;
      const shouldRemoveErrorBlocks = false;
      const content = await this.$editor.current.getContent(
        null,
        forPublish,
        shouldRemoveErrorBlocks,
      );
      this.props.onSubmit(content, this.state.topicId);
      this.props.onSaveDraft!(null as any);
      this.setState({ topicId: this.props.topicId!, post: null as any });
    }
  };

  handleTopicChange = (topicId: string) => this.setState({ topicId });

  setEditorEvents = (
    editorEvents: IEditorEventsContext,
    events: IEditorEvents,
  ) => {
    this.editorEvents = editorEvents;
    this.editorEventTypes = events;
  };
  render() {
    const { isOpen, mobile, forceBlackAndWhite } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        withCloseButton={!mobile}
        skin={ModalSkin.NEW_POST}
      >
        <div
          className={st(
            classes.root,
            { mobile, bw: forceBlackAndWhite } as any,
            this.props.className,
          )}
          data-hook={NEW_POST_MODAL}
        >
          {mobile ? this.renderMobileLayout() : this.renderDesktopLayout()}
        </div>
      </Modal>
    );
  }

  isPostButtonDisabled = () => {
    return !this.state.post || this.isLoading();
  };

  renderDesktopLayout() {
    const { topicId } = this.state;
    const {
      t,
      currentMember,
      isPostPublishing,
      experiments,
      feed,
      feedItemId,
      forceBlackAndWhite,
    } = this.props;
    const { name, imageUrl } = memberWrapper(currentMember);
    const isTopicEditable = !feed.feedFilters[EFilterKeys.TOPICS];

    return (
      <>
        <div className={classes.profileInfo}>
          <Avatar
            src={imageUrl!}
            size={AvatarSize.large}
            className={classes.avatar}
          />
          <Text className={classes.currentMemberName}>
            {name!.nick || t('groups-web.member.anonymous')}
          </Text>
        </div>
        {this.renderPostEditor()}
        <div className={classes.actionButtons}>
          {experiments.enabled('specs.groups.PostTopics') ? (
            <FeedTopics
              feedItemId={feedItemId}
              isEditable={isTopicEditable}
              topicId={topicId}
              onTopicChange={this.handleTopicChange}
            />
          ) : (
            <div />
          )}
          <div>
            <Button className={classes.cancelButton} onClick={this.handleClose}>
              {t('groups-web.discussion.new-post.cancel')}
            </Button>
            <Button
              forceBlackAndWhite={forceBlackAndWhite}
              priority={PRIORITY.primary}
              disabled={this.isPostButtonDisabled()}
              onClick={this.handleSubmit}
              prefixIcon={this.isLoading() ? <Loader /> : undefined}
            >
              {isPostPublishing
                ? t('groups-web.discussion.new-post.publishing')
                : t('groups-web.discussion.new-post.publish')}
            </Button>
          </div>
        </div>
      </>
    );
  }

  renderMobileLayout() {
    const { t, forceBlackAndWhite } = this.props;
    return (
      <>
        <div className={classes.mobileHeader}>
          <TextButton
            forceBlackAndWhite={forceBlackAndWhite}
            onClick={this.handleClose}
            priority={TEXT_BUTTON_PRIORITY.secondary}
          >
            {t('groups-web.discussion.new-post.mobile.back')}
          </TextButton>
          <Text
            typography={TYPOGRAPHY.smallTitle}
            className={classes.mobileTitle}
          >
            {t('groups-web.discussion.new-post.mobile.title')}
          </Text>
          <TextButton
            forceBlackAndWhite={forceBlackAndWhite}
            onClick={this.handleSubmit}
            priority={TEXT_BUTTON_PRIORITY.primary}
            disabled={this.isPostButtonDisabled()}
          >
            {t('groups-web.discussion.new-post.mobile.post')}
          </TextButton>
        </div>
        {this.renderPostEditor()}
      </>
    );
  }

  private isLoading() {
    const { isPostPublishing } = this.props;
    const { isBusy } = this.state;

    return isPostPublishing || isBusy;
  }

  private readonly handleBusyChange = (isBusy: boolean) => {
    this.setState({ isBusy });
  };

  private renderPostEditor() {
    const { mobile, initialContentState, t, forceBlackAndWhite, feedItemId } = this.props;
    return (
      <div className={classes.rceWrapper} data-hook="new-post-modal">
        <React.Suspense
          fallback={<Spinner forceBlackAndWhite={forceBlackAndWhite} />}
        >
          <RichContentEditor
            contentId={feedItemId}
            forceBlackAndWhite={forceBlackAndWhite}
            ref={this.$editor}
            autoFocus
            isMobile={mobile}
            content={initialContentState}
            onChange={this.setPost}
            initEditorEvents={this.setEditorEvents}
            onBusyChange={this.handleBusyChange}
            usage="NewPostModal"
            placeholder={t('groups-web.discussion.new-post.placeholder')}
          />
        </React.Suspense>
      </div>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withExperiments,
  withTPAConfig,
  WithGroup,
  withSentry,
  withTheme,
);

export const NewPostModal = enhance(NewPostModalComponent);
